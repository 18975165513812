
.columns {
  .column {
    .card {
      text-align: center;
      box-shadow: none;
    }
  }
}

.book-avatar {
  height: 40px;
  border-radius: 10px;
}

.dropdown {
  min-width: 200px;
}
